import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Label,
  Spinner,
  CardTitle,
  FormGroup,
  UncontrolledTooltip,
  TabPane,
} from "reactstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
const { default: axios } = require("axios");

import Breadcrumbs from "../../components/Common/Breadcrumb";

import api from "../../services/api.service";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { trendFormValidation } from "services/common.service";
import TradingView from "pages/TradingView/TradingView";

const TradingPairs = () => {
  firebase.auth();
  const [form1_loader, setForm1Loader] = useState({ loading: false });
  const [settingspair, setSettingspair] = useState("Trading Pair");
  const [ccxtAllExchanges, setCCXTAllExchanges] = useState([]);
  const [addPairVisible, setAddPairVisible] = useState(false);
  const [formLoader6, setFormLoader6] = useState(false);
  const [formLoader8, setFormLoader8] = useState(false);
  const [pairListLoading, setPairListLoading] = useState(true);
  const [separatePairs, setSeparatePairs] = useState([]);
  const [settingsvisible, setSettingsvisible] = useState(false);
  const [ShowWarning, setShowWarning] = useState(false);
  const [form7_loader, setForm7Loader] = useState({ loading: false });
  const [ToolTipData, setToolTipData] = useState([]);
  const [exchangeList, setExchangeList] = useState("all");
  const [isDeleteconfirmpopup, setisDeleteconfirmpopup] = useState(false);
  const [copiedSettings, setCopiedSettings] = useState("");

  const [PairId, setPairId] = useState();

  const [pairErrors, setPairErrors] = useState({
    exchange_error: "",
    pair_error: "",
  });
  const [pairExchange, setPairExchange] = useState("");
  const [SelectedSpotPair, setSelectedSpotPair] = useState({});

  const [spotPairs, setSpotPairs] = useState([]);
  const [pairOrderSettings, setPairOrderSettings] = useState({
    stop_loss_type: "percentage",
  });
  // const [pairTrendFollow, setPairTrendFollow] = useState({});
  const [pairStatus, setPairStatus] = useState({});
  const [Trendfollowpair, setTrendFollowpair] = useState("Trading Pair");
  const [trendSettings, setTrendSettings] = useState({
    trend_method: "sma",
    short_term: 9,
    long_term: 20,
    time_frame: "15m",
    st_time_frame: "15m",
    macd_time_frame: "15m",
    ema_time_frame: "15m",
    macd_long_term: 26,
    macd_short_term: 12,
    macd_signal_term: 9,
    ema_short_term: 9,
    ema_long_term: 21,
    st_atr_length: 10,
    st_factor: 2,
    trading_type: "short_term",
  });
  const [DefaultColorSetting, setDefaultColorSetting] = useState({});
  const [TrendFollowSettings, setTrendFollowSettings] = useState(false);
  const [form6_loader, setForm6Loader] = useState({ loading: false });
  const [loader, setloader] = useState(false);
  const [pasteSettingsModal, setPasteSettingsModal] = useState(false);
  const [pastePair, setPastePair] = useState({});

  const [deleteId, setDeleteId] = useState("");
  const [settingsLoad, setSettingsLoad] = useState(false);

  

  const handleWheel = (e) => {
    e.target.blur();
  };

  const pasteSettings = (exchange_id, pair, pair_id) => {
    console.log("copiedSettings", copiedSettings);
    if (copiedSettings) {
      setPastePair({ exchange_id: exchange_id, pair_id: pair_id, pair: pair });
      setPasteSettingsModal(true);
    }
  };

  const copySettings = (exchange_id, pair, trendsettings, ordersettings) => {
    let copiedSettingsval = {
      exchange_id: exchange_id,
      pair: pair,
      trendsettings: trendsettings ? JSON.parse(trendsettings) : "",
      ordersettings: ordersettings ? JSON.parse(ordersettings) : "",
    };
    setCopiedSettings(copiedSettingsval);
  };

  const pasteApply = async () => {
    setSettingsLoad(true);
    let pairinfo = pastePair;
    try {
      if (copiedSettings.ordersettings) {
        let bodyParams = copiedSettings.ordersettings;
        bodyParams.pair_id = pairinfo.pair_id;
        bodyParams.exchange_id = pairinfo.exchange_id;

        await api({ contentType: true, auth: true })
          .post("/ccxt/update-beginner-pair-order-settings", bodyParams)
          .then(async (res) => {
            if (res.status === 200) {
              if (exchangeList == "all") {
                getTradingPairs();
              } else {
                getTradingPairsById(exchangeList);
              }
              // toast.success("Settings updated");
              setSettingsvisible(false);
            }
          })
          .catch((err) => {
            throw err;
          });
      }
      if (copiedSettings.trendsettings) {
        let trendSettingsval = copiedSettings.trendsettings;
        console.log(trendSettingsval, "trendSettings val");
        trendSettingsval["id"] = pairinfo.pair_id;
        trendSettingsval["exchange_id"] = pairinfo.exchange_id;
        await api({ contentType: true, auth: true })
          .post("/ccxt/update-beginner-trend-settings", trendSettingsval)
          .then(async (res) => {
            if (res.status === 200) {
              if (exchangeList == "all") {
                getTradingPairs();
              } else {
                getTradingPairsById(exchangeList);
              }
              // setForm6Loader({
              //   loading: false,
              // });
              // toast.success("Trend following settings updated");
            }
          })
          .catch((err) => {
            throw err;
          });
      }
      toast.success("Settings updated successfully");
    } catch (err) {
      console.log("paste error", err);
      toast.error(
        "There is something went wrong when applying to copied changes, try again later"
      );
    } finally {
      setSettingsLoad(false);
      setPasteSettingsModal(false);
      setCopiedSettings("");
    }
  };

  const renderTrendSettings = () => {
    const {
      trend_method,
      short_term,
      long_term,
      time_frame,
      st_time_frame,
      macd_time_frame,
      ema_time_frame,
      ema_short_term,
      ema_long_term,
      exchange_id,
      display_text,
      macd_short_term,
      macd_long_term,
      macd_signal_term,
      st_atr_length,
      st_factor,
      st_max_distance,
      st_min_distance,
    } = copiedSettings.trendsettings;

    switch (trend_method) {
      case "sma":
        return (
          <>
            <p>
              Trend Method: <b>SMA</b>
              <br />
              Short Term: <b>{short_term}</b>
              <br />
              Long Term: <b>{long_term}</b>
              <br />
              Time Frame: <b>{time_frame}</b>
            </p>
          </>
        );
      case "ema":
        return (
          <>
            <p>
              Trend Method: <b>EMA</b>
              <br />
              Short Term: <b>{ema_short_term}</b>
              <br />
              Long Term: <b>{ema_long_term}</b>
              <br />
              Time Frame: <b>{ema_time_frame}</b>
            </p>
          </>
        );
      case "macd":
        return (
          <>
            <p>
              Trend Method: <b>MACD</b>
              <br />
              MACD Short Term: <b>{macd_short_term}</b>
              <br />
              MACD Long Term: <b>{macd_long_term}</b>
              <br />
              MACD Signal Term: <b>{macd_signal_term}</b>
              <br />
              Time Frame: <b>{macd_time_frame}</b>
            </p>
          </>
        );
      case "supertrend":
        return (
          <>
            <p>
              Trend Method: <b>Supertrend</b>
              <br />
              ATR length: <b>{st_atr_length}</b>
              <br />
              Factor: <b>{st_factor}</b>
              <br />
              Minimum supertrend distance:<b>{st_min_distance}</b>
              <br />
              Maximum supertrend distance:<b>{st_max_distance}</b>
              <br />
              Time Frame: <b>{st_time_frame}</b>
            </p>
          </>
        );
      // Add cases for other trend methods if any
      default:
        return <p>No valid trend method selected.</p>;
    }
  };

  const TrendFollowSettings1 = ({ trendSettingsInfo }) => {
    const trendSettings = JSON.parse(trendSettingsInfo.Trend_settings);
    // Helper function to format each setting
    const formatSetting = (label, value) => (
      <span>
        {label}: <b className="text-info">{value}</b>
      </span>
    );
    // Function to render settings based on the trend method
    const renderSettingsByMethod = (method) => {
      switch (method) {
        case "sma":
          return [
            trendSettings.trend_method && formatSetting("Trend Method", "SMA"),
            trendSettings.time_frame &&
            formatSetting("Interval", trendSettings.time_frame),
            trendSettings.short_term &&
            formatSetting("Short Length", trendSettings.short_term),
            trendSettings.long_term &&
            formatSetting("Long Length", trendSettings.long_term),
          ].filter(Boolean); // Filter out any undefined or null values
        case "ema":
          return [
            trendSettings.trend_method && formatSetting("Trend Method", "EMA"),
            trendSettings.ema_time_frame &&
            formatSetting("Interval", trendSettings.ema_time_frame),
            trendSettings.ema_short_term &&
            formatSetting("Short Length", trendSettings.ema_short_term),
            trendSettings.ema_long_term &&
            formatSetting("Long Length", trendSettings.ema_long_term),
          ].filter(Boolean); // Filter out any undefined or null values
        case "macd":
          return [
            trendSettings.trend_method && formatSetting("Trend Method", "MACD"),
            trendSettings.macd_time_frame &&
            formatSetting("Interval", trendSettings.macd_time_frame),
            trendSettings.macd_short_term &&
            formatSetting("MACD Short Length", trendSettings.macd_short_term),
            trendSettings.macd_long_term &&
            formatSetting("MACD Long Length", trendSettings.macd_long_term),
            trendSettings.macd_signal_term &&
            formatSetting(
              "MACD Signal Length",
              trendSettings.macd_signal_term
            ),
          ].filter(Boolean); // Filter out any undefined or null values
        case "supertrend":
          return [
            trendSettings.trend_method &&
            formatSetting("Trend Method", "Supertrend"),
            trendSettings.st_time_frame &&
            formatSetting("Interval", trendSettings.st_time_frame),
            trendSettings.st_atr_length &&
            formatSetting("ATR Length", trendSettings.st_atr_length),
            trendSettings.st_factor &&
            formatSetting("Factor", trendSettings.st_factor),
            trendSettings.st_min_distance &&
            trendSettings.st_min_distance.trim() !== "" &&
            formatSetting(
              "Minimum Supertrend Distance",
              trendSettings.st_min_distance
            ),
            trendSettings.st_max_distance &&
            trendSettings.st_max_distance.trim() !== "" &&
            formatSetting(
              "Maximum Supertrend Distance",
              trendSettings.st_max_distance
            ),
          ].filter(Boolean); // Filter out any undefined or null values
        default:
          return [];
      }
    };

    // Render the settings based on the trend method in the data
    const settingsToDisplay = renderSettingsByMethod(
      trendSettings.trend_method
    );
    // Helper function to split settings into chunks
    const orderSettingArray = (arr, size) => {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };
    const orderSettings = orderSettingArray(settingsToDisplay, 5);
    return orderSettings.length > 0
      ? orderSettings.map((order, index) => (
        <p key={index} className="text-secondary font-size-11 mb-1">
          {order.map((setting, i) => (
            <span key={i}>
              {setting}
              {i < order.length - 1 ? " | " : ""}
            </span>
          ))}
        </p>
      ))
      : "";
  };

  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }

  const handleDelete = async () => {
    setloader(true);
    try {
      let bodyParams = { id: deleteId };
      await api({ contentType: true, auth: true })
        .post("/ccxt/delete-beginner-exchange-pair", bodyParams)
        .then(async (res) => {
          if (res.data.message == "success") {
            if (exchangeList == "all") {
              getTradingPairs();
            } else {
              getTradingPairsById(exchangeList);
            }
            toast.success("Pair deleted successfully");
          } else {
            toast.error(
              "Users have enabled trading on this pair, so its status cannot be modified"
            );
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } catch (e) {
      handleError(e);
    } finally {
      setloader(false);
      setisDeleteconfirmpopup(false);
    }
  };

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours + ":" + minutes;
  }

  const updatePairOrderSettings = async () => {
    console.log("hiiiiii");
    try {
      setForm7Loader({
        loading: true,
      });
      if (!pairOrderSettings.fill_type || pairOrderSettings.fill_type == "") {
        toast.error("Please select fill type");
        setForm7Loader({
          loading: false,
        });
        return false;
      }
      if (
        pairOrderSettings.fill_type == "wait_and_fill" &&
        (!pairOrderSettings.price_distance_percentage ||
          pairOrderSettings.price_distance_percentage == "")
      ) {
        toast.error("Please enter price distance % for wait and fill");
        setForm7Loader({
          loading: false,
        });
        return false;
      }

      let exchange_id = settingspair.exchange_id;
      let pair_id = settingspair.id;
      let bodyParams = pairOrderSettings;
      if (
        pairOrderSettings &&
        pairOrderSettings.order_side &&
        (pairOrderSettings.order_side == "sell" ||
          pairOrderSettings.order_side == "buy")
      ) {
        //remove unwanted setting from bodyParams
        delete bodyParams.min_take_profit_percentage;
        delete bodyParams.stop_loss;
        delete bodyParams.trailing_take_profit_percentage;
        delete bodyParams.waiting_time_for_next_order_stop_loss;
        delete bodyParams.waiting_time_for_next_order_trailing_profit;
      }
      bodyParams.pair_id = pair_id;
      bodyParams.exchange_id = exchange_id;

      await api({ contentType: true, auth: true })
        .post("/ccxt/update-beginner-pair-order-settings", bodyParams)
        .then(async (res) => {
          if (res.status === 200) {
            if (exchangeList == "all") {
              getTradingPairs();
            } else {
              getTradingPairsById(exchangeList);
            }
            toast.success("Settings updated");
            setSettingsvisible(false);
          }
          setForm7Loader({
            loading: false,
          });
          // pairSettings[pair_id].settings = bodyParams;
          // setPairSettings(pairSettings);
          // TrendFollowSettingspair[pair_id].settings = bodyParams;
          // setTrendFollowSettingspair(TrendFollowSettingspair);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      setForm7Loader({
        loading: false,
      });
      error.response && toast.error(error.response.data.message);
    }
  };

  /*trend following settings**/
  const handleTrendSettingsChange = (e) => {
    setTrendSettings({
      ...trendSettings,
      [e.target.name]: e.target.value,
    });
    trendSettings[e.target.name] = e.target.value;
    if (e.target.name == "trading_type" || e.target.name == "trend_method") {
      //need to change the default values for trendSettings

      let jsonData = [
        {
          trading_type: "short_term",
          TIMEFRAME: "15m",
          EMA: "9,21",
          SMA: "9,20",
          SUPERTREND: "10,2",
          MACD: "12,26,9",
        },
        {
          trading_type: "medium_term",
          TIMEFRAME: "4h",
          EMA: "20,50",
          SMA: "21,55",
          SUPERTREND: "20,3",
          MACD: "12,26,9",
        },
        {
          trading_type: "long_term",
          TIMEFRAME: "1d",
          EMA: "50,200",
          SMA: "50,200",
          SUPERTREND: "30,9",
          MACD: "24,52,18",
        },
      ];
      console.log(
        "trendSettings",
        e.target.name,
        trendSettings,
        trendSettings.trading_type
      );
      const termData = jsonData.find(
        (item) => item.trading_type === trendSettings.trading_type
      );
      console.log(termData.SMA);
      if (trendSettings.trend_method == "sma") {
        trendSettings.short_term = termData.SMA.split(",")[0];
        trendSettings.long_term = termData.SMA.split(",")[1];
        trendSettings.time_frame = termData.TIMEFRAME;
      } else if (trendSettings.trend_method == "ema") {
        trendSettings.ema_short_term = termData.EMA.split(",")[0];
        trendSettings.ema_long_term = termData.EMA.split(",")[1];
        trendSettings.ema_time_frame = termData.TIMEFRAME;
      } else if (trendSettings.trend_method == "supertrend") {
        trendSettings.st_atr_length = termData.SUPERTREND.split(",")[0];
        trendSettings.st_factor = termData.SUPERTREND.split(",")[1];
        trendSettings.st_time_frame = termData.TIMEFRAME;
      } else if (trendSettings.trend_method == "macd") {
        trendSettings.macd_short_term = termData.MACD.split(",")[0];
        trendSettings.macd_long_term = termData.MACD.split(",")[1];
        trendSettings.macd_signal = termData.MACD.split(",")[2];
        trendSettings.macd_time_frame = termData.TIMEFRAME;
      }
    }
    setTrendSettings(trendSettings);
    setDefaultColorSetting({
      ...DefaultColorSetting,
      [e.target.name]: e.target.value,
    });
  };

  const changePairStatus = async (
    settingspair,
    exchange_id,
    pair_id,
    statusval,
    id
  ) => {
    try {
      setPairStatus({ ...pairStatus, [pair_id]: statusval });
      let bodyParams = {
        exchange_id: exchange_id,
        symbol_id: pair_id,
        status: statusval,
        id,
      };
      await api({ contentType: true, auth: true })
        .post("/ccxt/update-beginner-exchange-pair", bodyParams)
        .then(async (res) => {
          if (res.data.message == "success") {
            if (exchangeList == "all") {
              getTradingPairs();
            } else {
              getTradingPairsById(exchangeList);
            }
            toast.success("Pair status updated successfully");
          } else {
            toast.error(
              "Users have enabled trading on this pair, so its status cannot be modified"
            );
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } catch (e) {
      handleError(e);
    }
  };

  const checkValidation = () => {
    let valid = true;
    if (
      pairExchange == "" &&
      (Object.keys(SelectedSpotPair).length == 0 ||
        SelectedSpotPair.value != "")
    ) {
      setPairErrors({
        exchange_error: "Select Exchange",
        pair_error: "Select Pair",
      });
      valid = false;
    } else if (pairExchange == "") {
      setPairErrors({ pair_error: "", exchange_error: "Select Exchange" });
      valid = false;
    } else if (
      Object.keys(SelectedSpotPair).length == 0 ||
      SelectedSpotPair.value == ""
    ) {
      setPairErrors({ exchange_error: "", pair_error: "Select Pair" });
      valid = false;
    } else {
      setPairErrors({ exchange_error: "", pair_error: "" });
    }
    return valid;
  };

  const updateNewTrendSettings = async () => {
    try {
      setForm6Loader({
        loading: true,
      });

      trendSettings["id"] = Trendfollowpair.id;
      trendSettings["exchange_id"] = Trendfollowpair.exchange_id;
      let bodyParams = trendSettings;
      let errmsg = trendFormValidation(trendSettings);
      if (errmsg != "") {
        throw toast.error(errmsg);
      }
      await api({ contentType: true, auth: true })
        .post("/ccxt/update-beginner-trend-settings", bodyParams)
        .then(async (res) => {
          if (exchangeList == "all") {
            getTradingPairs();
          } else {
            getTradingPairsById(exchangeList);
          }
          setForm6Loader({
            loading: false,
          });
          toast.success("Trend following settings updated");
          setTrendFollowSettings(false);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      setForm6Loader({
        loading: false,
      });

      error.response &&
        error.response.data &&
        error.response.data.message &&
        toast.error(error.response.data.message);
    }
  };

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedSpotPair(selectedList);
  };

  const addNewPair = async () => {
    setFormLoader6(true);
    if (checkValidation()) {
      let bodyParams = {
        exchange_id: pairExchange,
        symbol_id: SelectedSpotPair?.value,
        is_add_pair: 1,
        symbol: SelectedSpotPair?.value,
      };

      await api({ contentType: true, auth: true })
        .post("/ccxt/add-ccxt-beginner-pairs", bodyParams)
        .then(async (res) => {
          toast.success("Pair added successfully");
          setFormLoader6(false);
          setAddPairVisible(false);
          setPairExchange("");
          setExchangeList("all");
          getTradingPairs();
        })
        .catch((err) => {
          handleError(err);
          setFormLoader6(false);
          setAddPairVisible(false);
          setPairListLoading(false);
        });
    } else {
      setFormLoader6(false);
    }
  };
  const handlePairExchange = async (e) => {
    setFormLoader8(true);
    setSpotPairs([]);
    setSelectedSpotPair({});
    let exchange_id = e.target.value;
    if (exchange_id != "") {
      await api({ contentType: true, auth: true })
        .get("/ccxt/get-spot-symbols-beginner/" + exchange_id)
        .then((res) => {
          // console.log('res.data....',res.data);
          setPairListLoading(false);
          //setSpotPairs
          const symbolsListResponse = res.data.response.symbols;
          if (symbolsListResponse.length) {
            const result = symbolsListResponse.map((item) => {
              return {
                label: item.id.replace("_", "/"),
                value: item.id,
              };
            });
            setPairExchange(exchange_id);
            setSpotPairs(result);
            setFormLoader8(false);
          } else {
            toast.error("No pairs Available");
            setFormLoader8(false);
            setPairExchange("");
            setAddPairVisible(false);
          }
        })
        .catch((err) => {
          toast.error("No pairs Available");
          setFormLoader8(false);
          setPairExchange("");
          setAddPairVisible(false);
          handleError(err);
        });
    } else {
      setSpotPairs([]);
      setFormLoader8(false);
      setPairExchange("");
    }
  };
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, {
        toastId: err.response.data.message,
      });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  };

  const getCCXTAllExchangesList = async () => {
    try {
      await api({ contentType: true, auth: true })
        .get("/ccxt/all-exchanges")
        .then((res) => {
          console.log(res, "res ccxt");
          setCCXTAllExchanges(res.data.response.exchanges);
          // resolve(res)
        })
        .catch((error) => {
          handleError(error);
          // reject(error)
        });
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    getTradingPairs();
    getCCXTAllExchangesList();

    try {
      const gettoottipData = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/static/helptexts.json`
        );
        setToolTipData(JSON.parse(JSON.stringify(response.data)));
      };
      gettoottipData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getTradingPairs = async () => {
    setForm1Loader({
      loading: true,
    });
    await api({ contentType: true, auth: true })
      .get("/abotex/user/getTradingPairs")
      .then((res) => {
        console.log(res, "pairsssssss");

        // setGetAllUsers(res.data.response.data);
        setSeparatePairs(res.data.response.data);
        setPairListLoading(false);

        setForm1Loader({
          loading: false,
        });
      })
      .catch((err) => {
        setForm1Loader({
          loading: false,
        });
        console.log(err);
        toast.error(
          err.response?.data?.message ||
          err.message ||
          "Something went wrong, Try again later"
        );
        // throw err;
      });
  };

  const getTradingPairsById = async (val) => {
    const bodyParams = {
      exchange_id: val,
    };
    setForm1Loader({
      loading: true,
    });
    await api({ contentType: true, auth: true })
      .post("/abotex/user/getTradingPairsById", bodyParams)
      .then((res) => {
        console.log(res, "pairsssssss");

        setSeparatePairs(res.data.response.data);

        setForm1Loader({
          loading: false,
        });
      })
      .catch((err) => {
        setForm1Loader({
          loading: false,
        });
        console.log(err);
        toast.error(
          err.response?.data?.message ||
          err.message ||
          "Something went wrong, Try again later"
        );
        // throw err;
      });
  };

  const handlePairOrderSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
        const numValue = parseInt(value, 10); // Convert value to a number if needed

        setPairOrderSettings((prevSettings) => {
            if (type === "checkbox") {
                const currentValues =  Array.isArray(prevSettings[name]) ? prevSettings[name] : [prevSettings[name]];; // Get existing array or default to empty

                return {
                    ...prevSettings,
                    [name]: checked
                        ? [...currentValues, numValue] // Add value if checked
                        : currentValues.filter((item) => item !== numValue), // Remove if unchecked
                };
            } else {
                return {
                    ...prevSettings,
                    [name]: value, // Handle other input types (text, select, etc.)
                };
            }
        });
 
  };

  const handleOrderInterval = (e, name) => {
    var time = e[0];
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    setPairOrderSettings({
      ...pairOrderSettings,
      [name]: H_M,
    });
  };

  const timeFormat = (seconds) => {
    var totalNumberOfSeconds = seconds;
    var hours = parseInt(totalNumberOfSeconds / 3600);
    var minutes = parseInt((totalNumberOfSeconds - hours * 3600) / 60);
    var seconds = Math.floor(
      totalNumberOfSeconds - (hours * 3600 + minutes * 60)
    );
    if (seconds > 0) {
      var result =
        (hours < 10 ? "0" + hours : hours) +
        "h " +
        (minutes < 10 ? "0" + minutes : minutes) +
        "m " +
        (seconds < 10 ? "0" + seconds : seconds) +
        "s";
    } else {
      var result =
        (hours < 10 ? "0" + hours : hours) +
        "h " +
        (minutes < 10 ? "0" + minutes : minutes) +
        "m";
    }
    return result;
  };

  const arrangeSettingsArray = (settinginfo) => {
    const formatSetting = (label, value) => (
      <span>
        {label}: <b className="text-info">{value}</b>
      </span>
    );

    const settingsArray = [
      settinginfo &&
      settinginfo.fill_type &&
      formatSetting(
        "Fill type",
        settinginfo.fill_type === "instant_fill"
          ? "Instant Fill"
          : "Wait and Fill"
      ),
      settinginfo &&
      settinginfo.price_distance_percentage &&
      settinginfo.fill_type !== "instant_fill" &&
      formatSetting(
        "Price Distance % for Wait and Fill",
        settinginfo.price_distance_percentage
      ),
      settinginfo &&
      settinginfo.stop_loss &&
      formatSetting(
        "Market Stop Loss",
        `${settinginfo.stop_loss}${settinginfo.stop_loss_type === "points" ? "Pts" : "%"
        }`
      ),
      settinginfo &&
      settinginfo.min_take_profit_percentage &&
      formatSetting(
        "Minimum Take Profit%",
        settinginfo.min_take_profit_percentage
      ),
      settinginfo &&
      settinginfo.trailing_take_profit_percentage &&
      formatSetting(
        "Trailing Take Profit%",
        settinginfo.trailing_take_profit_percentage
      ),
      settinginfo &&
      settinginfo.waiting_time_for_next_order_stop_loss &&
      formatSetting(
        "Waiting Time for Order after Stop Loss",
        timeFormat(settinginfo.waiting_time_for_next_order_stop_loss)
      ),
      settinginfo &&
      settinginfo.waiting_time_for_next_order_trailing_profit &&
      formatSetting(
        "Waiting Time for Order after Trailing Take Profit",
        timeFormat(settinginfo.waiting_time_for_next_order_trailing_profit)
      ),
      settinginfo &&
      settinginfo.order_side &&
      formatSetting("Order Side", settinginfo.order_side),
      settinginfo &&
      settinginfo.order_side === "sell" &&
      settinginfo.min_sell_price &&
      formatSetting("Minimum Sell Price", settinginfo.min_sell_price),
      settinginfo &&
      settinginfo.order_side === "buy" &&
      settinginfo.max_buy_price &&
      formatSetting("Maximum Buy Price", settinginfo.max_buy_price),
    ].filter(Boolean);

    const orderSettingArray = (arr, size) => {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };

    const orderSettings = orderSettingArray(settingsArray, 5);
    return orderSettings;
  };

  const handleChangeExchange = (e) => {
    if (e.target.value == "all") {
      getTradingPairs();
    } else {
      getTradingPairsById(e.target.value);
    }
    setExchangeList(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="DeriSnap" breadcrumbItem="Trading pairs" />
          <Row className="mb-4">
            <Col md={6}>
              <Label
                htmlFor="horizontal-firstname-Input"
                className="col-form-label"
              >
                Filter By Exchange
              </Label>
              <select
                value={exchangeList}
                className="form-select"
                onChange={async (e) => {
                  handleChangeExchange(e);
                }}
              >
                <option value="all">All Exchange Pairs</option>
                {ccxtAllExchanges.map((exchange, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={exchange.id}> {exchange.name}</option>
                    </React.Fragment>
                  );
                })}
              </select>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-end justify-content-sm-end mt-3"
            >
              <button
                className="btn btn-success mx-sm-5"
                onClick={() => {
                  setAddPairVisible(true);
                }}
              >
                <i className="bx bx-plus align-middle"></i>&nbsp;Add Pair
              </button>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              {separatePairs && separatePairs.length > 0 ? (
                <Row>
                  <Card className="border p-3 rounded mt-4">
                    {" "}
                    <CardTitle className="px-0"></CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap align-middle mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Exchange</th>
                            <th scope="col" className="col-md-6">
                              Pair
                            </th>
                            <th scope="col">Status</th>
                            <th scope="col">Settings</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!pairListLoading &&
                            separatePairs &&
                            separatePairs.length ? (
                            separatePairs.map((exchangedata, index) => {
                              // console.log(exchangedata,"dataaaaaaaaaaaaaaaaaa");

                              let exchange = exchangedata.data;
                              let settinginfo = exchange.settings
                                ? JSON.parse(exchange.settings)
                                : {};
                              // console.log(settinginfo, "settinggggggggg");
                              let settinginfo2 = exchange;
                              let TrendFollowSettingsinfo = exchange;
                              let pairStatus =
                                exchange.status == "true" ||
                                  exchange.status == 1
                                  ? 1
                                  : 0;
                              return (
                                <tr key={index}>
                                  <th>{exchange.exchange_id}</th>
                                  <th scope="row">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <span className="text-primary">
                                          {exchange.display_text}{" "}
                                          <a
                                            className="text-danger font-size-14"
                                            onClick={() => {
                                              // var id = exchangedata.data.symbol
                                              // var exchange_id = exchangedata.data.exchange_id
                                              setisDeleteconfirmpopup(true);

                                              setPairId(
                                                exchangedata.data.symbol
                                              );
                                              setDeleteId(exchangedata.id);
                                            }}
                                          >
                                            <i className="bx bx-trash"></i>
                                          </a>
                                        </span>

                                        {settinginfo.fill_type &&
                                          settinginfo.order_side ? (
                                          arrangeSettingsArray(settinginfo).map(
                                            (order, index) => (
                                              <p
                                                key={index}
                                                className="text-secondary font-size-11 mb-1"
                                              >
                                                {order.map((setting, i) => (
                                                  <span key={i}>
                                                    {setting}
                                                    {i < order.length - 1
                                                      ? " | "
                                                      : ""}
                                                  </span>
                                                ))}
                                              </p>
                                            )
                                          )
                                        ) : (
                                          <div className="text-warning font-size-11">
                                            Please Add Order Settings
                                          </div>
                                        )}
                                        {TrendFollowSettingsinfo.Trend_settings &&
                                          Object.keys(
                                            JSON.parse(
                                              TrendFollowSettingsinfo.Trend_settings
                                            )
                                          ).length > 0 ? (
                                          <TrendFollowSettings1
                                            trendSettingsInfo={
                                              TrendFollowSettingsinfo
                                            }
                                          />
                                        ) : (
                                          // <></>
                                          <div className="text-warning font-size-11">
                                            Please Add Trend Settings
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="d-flex align-items-center mt-1">
                                      <div className="avatar-xs me-3">
                                        <FormGroup className="" row>
                                          <Col md="8">
                                            <Switch
                                              uncheckedIcon={<Offsymbol />}
                                              checkedIcon={<OnSymbol />}
                                              onChange={async (e) => {
                                                try {
                                                  changePairStatus(
                                                    exchange,
                                                    exchange.exchange_id,
                                                    exchange.id,
                                                    e,
                                                    exchangedata.id
                                                  );
                                                } catch (error) {
                                                  console.error(
                                                    "Error during validation or submission:",
                                                    error
                                                  );
                                                }
                                              }}
                                              checked={pairStatus}
                                            />
                                          </Col>
                                        </FormGroup>
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                    <ul className="list-unstyled hstack gap-1 mb-0">
                                      <li title="Order Config">
                                        <a
                                          className="btn btn-sm btn-soft-primary"
                                          onClick={() => {
                                            setSettingsvisible(
                                              !settingsvisible
                                            );

                                            let orderSettings =
                                              settinginfo2.settings
                                                ? JSON.parse(
                                                  settinginfo2.settings
                                                )
                                                : {
                                                  fill_type: "instant_fill",
                                                  order_side: "both",
                                                };
                                            if (!orderSettings.fill_type) {
                                              orderSettings = {
                                                ...orderSettings,
                                                fill_type: "instant_fill",
                                              };
                                            }

                                            if (!orderSettings.order_side) {
                                              orderSettings = {
                                                ...orderSettings,
                                                order_side: "both",
                                              };
                                            }

                                            console.log(orderSettings, "order");
                                            setSettingspair(exchange);
                                            setPairOrderSettings(orderSettings);
                                          }}
                                        >
                                          <i className="bx bx-cog font-size-18"></i>
                                        </a>
                                      </li>
                                      <li title="Strategy Config">
                                        <a
                                          className="btn btn-sm btn-soft-info"
                                          onClick={() => {
                                            setTrendFollowSettings(
                                              !TrendFollowSettings
                                            );
                                            setTrendFollowpair(exchange);
                                            setTrendSettings(
                                              TrendFollowSettingsinfo &&
                                                TrendFollowSettingsinfo.Trend_settings !==
                                                undefined
                                                ? JSON.parse(
                                                  TrendFollowSettingsinfo.Trend_settings
                                                )
                                                : {
                                                  trend_method: "sma",
                                                  short_term: 9,
                                                  long_term: 20,
                                                  time_frame: "15m",
                                                  st_time_frame: "15m",
                                                  macd_time_frame: "15m",
                                                  ema_time_frame: "15m",
                                                  exchange_id:
                                                    exchange.exchange_id,
                                                  ema_long_term: 21,
                                                  ema_short_term: 9,
                                                  macd_long_term: 26,
                                                  macd_short_term: 12,
                                                  trading_type: "short_term",
                                                }
                                            );
                                            setShowWarning(false);
                                            setDefaultColorSetting(
                                              TrendFollowSettingsinfo &&
                                                TrendFollowSettingsinfo.Trend_settings !==
                                                undefined
                                                ? JSON.parse(
                                                  TrendFollowSettingsinfo.Trend_settings
                                                )
                                                : {}
                                            );
                                          }}
                                        >
                                          <i className="bx bx-line-chart font-size-18"></i>
                                        </a>
                                      </li>

                                      {(settinginfo2 &&
                                        settinginfo2.settings !== undefined) ||
                                        (TrendFollowSettingsinfo &&
                                          TrendFollowSettingsinfo.Trend_settings !==
                                          undefined) ? (
                                        <li title="Copy Config">
                                          <a
                                            className="btn btn-sm btn-soft-secondary"
                                            onClick={() =>
                                              copySettings(
                                                exchange.exchange_id,
                                                exchange.display_text,
                                                TrendFollowSettingsinfo.Trend_settings,
                                                settinginfo2.settings
                                              )
                                            }
                                          >
                                            <i className="bx bx-copy-alt  font-size-18"></i>
                                          </a>
                                        </li>
                                      ) : (
                                        ""
                                      )}

                                      {copiedSettings &&
                                        (copiedSettings["exchange_id"] !==
                                          exchange.exchange_id ||
                                          copiedSettings["pair"] !==
                                          exchange.display_text) ? (
                                        <li title="Paste Config">
                                          <a
                                            className="btn btn-sm btn-soft-warning"
                                            onClick={() => {
                                              // setShowWarning(false);
                                              pasteSettings(
                                                exchange.exchange_id,
                                                exchange.display_text,
                                                exchange.id,
                                                TrendFollowSettingsinfo.Trend_settings,
                                                settinginfo2.settings
                                              );
                                            }}
                                          >
                                            <i className="bx bx-paste  font-size-18"></i>
                                          </a>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </ul>
                                  </th>
                                </tr>
                              );
                            })
                          ) : pairListLoading ? (
                            <tr>
                              <td colSpan={6}>
                                <Col xl={12}>
                                  <div className="p-4 border text-center">
                                    <h6 className="">Loading...</h6>
                                  </div>
                                </Col>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <Col xl={12}>
                                  <div className="p-4 border text-center">
                                    <h6 className="">No Pairs</h6>
                                  </div>
                                </Col>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>{" "}
                  </Card>
                </Row>
              ) : form1_loader.loading ? (
                <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin me-2" />
                        Loading
                      </Link>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div className="text-center">No Pairs Found</div>
              )}
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={addPairVisible}
        toggle={() => {
          setAddPairVisible(false);
          setPairErrors({ exchange_error: "", pair_error: "" });
          setSpotPairs([]);
          setPairExchange("");
          setSelectedSpotPair({});
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setAddPairVisible(false);
            setPairErrors({ exchange_error: "", pair_error: "" });
            setSpotPairs([]);
            setPairExchange("");
            setSelectedSpotPair({});
          }}
          tag="h4"
          className="text-capitalize"
        >
          Add Pair
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              addNewPair();
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Select Exchange</Label>
                  <select
                    id="select-exchange-pair"
                    name="pair_exchange_id"
                    value={pairExchange}
                    selected={true}
                    className="form-select"
                    onChange={async (e) => {
                      handlePairExchange(e);
                    }}
                  >
                    <option value="">Select Exchange</option>
                    {ccxtAllExchanges.map((exchange, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={exchange.id}> {exchange.name}</option>
                        </React.Fragment>
                      );
                    })}
                  </select>

                  {pairErrors.exchange_error ? (
                    <div
                      style={{
                        color: "#f46a6a",
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "80%",
                      }}
                    >
                      {pairErrors.exchange_error}
                    </div>
                  ) : null}
                </div>

                {formLoader8 ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : null}
                {pairExchange != "" && !formLoader8 ? (
                  <div className="mb-3">
                    <Label className="form-label">Select Pair</Label>
                    <Select
                      id="select-add-pair"
                      placeholder="Add new pair"
                      name="pair_symbol"
                      onChange={handleSelect}
                      options={spotPairs}
                      classNamePrefix="select2-selection"
                    ></Select>
                    {pairErrors.pair_error ? (
                      <div
                        style={{
                          color: "#f46a6a",
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                        }}
                      >
                        {pairErrors.pair_error}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={
                      formLoader6
                        ? "btn btn-success save-user disabled"
                        : "btn btn-success save-user"
                    }
                  >
                    {formLoader6 ? (
                      <span>
                        <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                        Processing...{" "}
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={settingsvisible}
        toggle={() => {
          setSettingsvisible(false);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setSettingsvisible(false);
          }}
          tag="h4"
          className="text-capitalize"
        >
          Settings for{" "}
          <span className="text-primary">{settingspair.display_text}</span> Pair{" "}
        </ModalHeader>
        <ModalBody>
          <form
            key={6}
            onSubmit={async (e) => {
              e.preventDefault(); // Prevent default form submission behavior

              try {
                updatePairOrderSettings();
                // }
              } catch (error) {
                console.error("Error during validation or submission:", error);
              }
            }}
          >
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    md="3"
                    className="col-form-label"
                  >
                    Fill type{" "}
                    <i className="bx bx-info-circle" id="Fill_type1" />
                    <UncontrolledTooltip placement="top" target="Fill_type1">
                      {ToolTipData.ccxt_trading_fill_type}
                    </UncontrolledTooltip>
                  </Label>
                  <select
                    name="fill_type"
                    label="fill_type"
                    className="form-select"
                    onChange={(e) => handlePairOrderSettingsChange(e)}
                    value={
                      pairOrderSettings && pairOrderSettings.fill_type
                        ? pairOrderSettings.fill_type
                        : "instant_fill"
                    }
                  >
                    <option value="">Select Fill Type</option>
                    <option value="instant_fill">
                      Instant Fill (Market order)
                    </option>
                    <option value="wait_and_fill">
                      Wait and Fill (Limit order)
                    </option>
                  </select>
                </FormGroup>
              </Col>
            </Row>

            {pairOrderSettings.fill_type === "wait_and_fill" ? (
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label htmlFor="formrow-firstname-Input">
                      Price Distance % for Wait and Fill (Limit Order){" "}
                      <i className="bx bx-info-circle" id="Price_distance" />
                      <UncontrolledTooltip
                        placement="top"
                        target="Price_distance"
                      >
                        {ToolTipData.ccxt_price_distance}
                      </UncontrolledTooltip>
                    </Label>
                    <input
                      autoComplete="off"
                      type="number"
                      value={
                        pairOrderSettings &&
                          pairOrderSettings.price_distance_percentage
                          ? pairOrderSettings.price_distance_percentage
                          : ""
                      }
                      onChange={(e) => handlePairOrderSettingsChange(e)}
                      onBlur={(e) => handlePairOrderSettingsChange(e)}
                      name="price_distance_percentage"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Label
                    htmlFor="formrow-firstname-Input"
                    md="3"
                    className="col-form-label"
                  >
                    Select Side{" "}
                    <i className="bx bx-info-circle" id="order_side" />
                    <UncontrolledTooltip placement="top" target="order_side">
                      {ToolTipData.ccxt_order_side}
                    </UncontrolledTooltip>
                  </Label>
                  <select
                    name="order_side"
                    label="order_side"
                    className="form-select"
                    onChange={(e) => handlePairOrderSettingsChange(e)}
                    value={pairOrderSettings && pairOrderSettings.order_side}
                  >
                    <option value="both">Both Short & Long</option>
                    <option value="short">Short</option>
                    <option value="long">Long</option>
                    <option value="sell">Sell</option>
                    <option value="buy">Buy</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>

            {pairOrderSettings &&
              pairOrderSettings.order_side &&
              pairOrderSettings.order_side !== "sell" &&
              pairOrderSettings.order_side !== "buy" ? (
              <>
                <Row>
                  <Col>
                    <div className="">
                      <div>
                        <Label className="form-label">
                          Stop Loss{" "}
                          <i className="bx bx-info-circle" id="Stop_loss" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Stop_loss"
                          >
                            {ToolTipData.ccxt_stop_loss}
                          </UncontrolledTooltip>{" "}
                        </Label>
                        <FormGroup className="input-group mb-0 ">
                          <select
                            className="form-control"
                            name="stop_loss_type"
                            id="inputGroupFileAddon04"
                            onChange={(e) => handlePairOrderSettingsChange(e)}
                            value={
                              pairOrderSettings &&
                              pairOrderSettings.stop_loss_type
                            }
                          >
                            <option value="percentage">%</option>
                            <option value="points">Pts</option>
                          </select>
                          <input
                            autoComplete="off"
                            type="number"
                            min={0}
                            max={
                              pairOrderSettings &&
                                pairOrderSettings.stop_loss_type != "points"
                                ? 100
                                : 10000
                            }
                            step={0.1}
                            value={
                              pairOrderSettings && pairOrderSettings.stop_loss
                                ? pairOrderSettings.stop_loss
                                : ""
                            }
                            onChange={(e) => handlePairOrderSettingsChange(e)}
                            onBlur={(e) => handlePairOrderSettingsChange(e)}
                            name="stop_loss"
                            className="form-control w-50"
                            aria-describedby="inputGroupFileAddon04"
                          />
                        </FormGroup>{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Trailing Take Profit %{" "}
                        <i
                          className="bx bx-info-circle"
                          onClick={() => setVisibleMin(!visibleMin)}
                        ></i>
                      </Label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={
                          pairOrderSettings &&
                            pairOrderSettings.min_take_profit_percentage
                            ? pairOrderSettings.min_take_profit_percentage
                            : ""
                        }
                        onChange={(e) => handlePairOrderSettingsChange(e)}
                        onBlur={(e) => handlePairOrderSettingsChange(e)}
                        name="min_take_profit_percentage"
                        className="form-control"
                      />
                      {settingspair.maker && settingspair.taker ? (
                        <div className="text-info font-size-10">
                          Taker Fee: <b>{Number(settingspair.maker) * 100}%</b>,
                          Maker Fee: <b>{Number(settingspair.taker) * 100}%</b>
                        </div>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Trailing Stop Price %{" "}
                        <i
                          className="bx bx-info-circle"
                          onClick={() => setVisibleTailling(!visibleTailling)}
                        ></i>
                      </Label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={
                          pairOrderSettings &&
                            pairOrderSettings.trailing_take_profit_percentage
                            ? pairOrderSettings.trailing_take_profit_percentage
                            : ""
                        }
                        onChange={(e) => handlePairOrderSettingsChange(e)}
                        onBlur={(e) => handlePairOrderSettingsChange(e)}
                        name="trailing_take_profit_percentage"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>

              </>
            ) : (
              ""
            )}

            {pairOrderSettings &&
              pairOrderSettings.order_side &&
              pairOrderSettings.order_side == "sell" ? (
              <>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Minimum Sell Price{" "}
                        <i
                          className="bx bx-info-circle"
                          id="min_sell_price_tar"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="min_sell_price_tar"
                        >
                          {ToolTipData.ccxt_min_sell_price}
                        </UncontrolledTooltip>
                      </Label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={
                          pairOrderSettings && pairOrderSettings.min_sell_price
                            ? pairOrderSettings.min_sell_price
                            : ""
                        }
                        onChange={(e) => handlePairOrderSettingsChange(e)}
                        onBlur={(e) => handlePairOrderSettingsChange(e)}
                        name="min_sell_price"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {pairOrderSettings &&
              pairOrderSettings.order_side &&
              pairOrderSettings.order_side == "buy" ? (
              <>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Maximum Buy Price{" "}
                        <i
                          className="bx bx-info-circle"
                          id="max_buy_price_tar"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="max_buy_price_tar"
                        >
                          {ToolTipData.ccxt_max_buy_price}
                        </UncontrolledTooltip>
                      </Label>
                      <input
                        autoComplete="off"
                        type="number"
                        value={
                          pairOrderSettings && pairOrderSettings.max_buy_price
                            ? pairOrderSettings.max_buy_price
                            : ""
                        }
                        onChange={(e) => handlePairOrderSettingsChange(e)}
                        onBlur={(e) => handlePairOrderSettingsChange(e)}
                        name="max_buy_price"
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {pairOrderSettings &&
              pairOrderSettings.order_side &&
              pairOrderSettings.order_side !== "sell" &&
              pairOrderSettings.order_side !== "buy" ? (
              <>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Waiting Time for Order after Stop Loss (H : M){" "}
                        <i className="bx bx-info-circle" id="WTstoploss" />
                        <UncontrolledTooltip
                          placement="top"
                          target="WTstoploss"
                        >
                          {ToolTipData.intervalfororder_stop_loss}
                        </UncontrolledTooltip>
                      </Label>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Select time"
                        name="waiting_time_for_next_order_stop_loss"
                        onChange={(e) =>
                          handleOrderInterval(
                            e,
                            "waiting_time_for_next_order_stop_loss"
                          )
                        }
                        value={
                          pairOrderSettings &&
                            pairOrderSettings.waiting_time_for_next_order_stop_loss
                            ? toHoursAndMinutes(
                              pairOrderSettings.waiting_time_for_next_order_stop_loss
                            )
                            : ""
                        }
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label htmlFor="formrow-firstname-Input">
                        Waiting Time for Order after Trailing Take Profit (H :
                        M) <i className="bx bx-info-circle" id="WTtakeprofit" />
                        <UncontrolledTooltip
                          placement="top"
                          target="WTtakeprofit"
                        >
                          {ToolTipData.intervalfororder_trailing_take_profit}
                        </UncontrolledTooltip>
                      </Label>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Select time"
                        name="waiting_time_for_next_order_trailing_profit"
                        onChange={(e) =>
                          handleOrderInterval(
                            e,
                            "waiting_time_for_next_order_trailing_profit"
                          )
                        }
                        value={
                          pairOrderSettings &&
                            pairOrderSettings.waiting_time_for_next_order_trailing_profit
                            ? toHoursAndMinutes(
                              pairOrderSettings.waiting_time_for_next_order_trailing_profit
                            )
                            : ""
                        }
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side !== "sell" && pairOrderSettings.order_side !== "buy" ?

              <Row>
                <FormGroup className="mb-4">
                  {/* <Label className="col-form-label d-block">Exit on</Label> */}
                  <Col className="mt-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="exit_on"
                        value={1}
                        onChange={handlePairOrderSettingsChange}
                        checked={pairOrderSettings.exit_on?.includes(1)}
                      />
                      <label className="form-check-label">Exit on Trend Change</label>
                    </div>
                   
                  </Col>
                </FormGroup>
              </Row> : null}

            <div className="text-sm-end">
              <Button
                className="btn-label w-md"
                color="success"
                type="submit"
                disabled={form7_loader.loading}
              >
                {form7_loader.loading ? (
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                ) : (
                  ""
                )}
                <i className="bx bx-smile label-icon"></i> Submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={TrendFollowSettings}
        toggle={() => {
          setTrendFollowSettings(false);
        }}
        style={{ width: "100%" }}
        className="modal-xl"
      >
        <ModalHeader
          toggle={() => {
            setTrendFollowSettings(false);
          }}
          tag="h4"
          className="text-capitalize"
        >
          Trend Following Settings For{" "}
          <span className="text-primary">{Trendfollowpair.display_text}</span>{" "}
          Pair{" "}
        </ModalHeader>
        <div className="modal-body">
          <ModalBody>
            <TabPane tabId="2">
              <form
                key={6}
                onSubmit={async (e) => {
                  e.preventDefault(); // Prevent default form submission behavior

                  try {
                    // const hasActiveOrders = await validateActiveOrders(
                    //   Trendfollowpair.id,
                    //   Trendfollowpair.exchange_id
                    // ); // Await validation
                    // setShowWarning(hasActiveOrders);
                    // if (hasActiveOrders) {
                    // toast.error("Please Close All Active Orders For This Pair");
                    // } else {
                    updateNewTrendSettings(); // Proceed with the form submission
                    // }
                  } catch (error) {
                    console.error(
                      "Error during validation or submission:",
                      error
                    );
                  }
                }}
              >
                <div>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="formrow-firstname-Input"
                      md="4"
                      className="col-form-label"
                    >
                      Trading type:{" "}
                      <i className="bx bx-info-circle" id="trading_type" />
                      <UncontrolledTooltip
                        placement="top"
                        target="trading_type"
                      >
                        {
                          <>
                            Please select the type of trading you prefer:
                            <br />- Short term - INTRADAY:
                            <br />
                            This option is suitable for traders who aim to
                            complete their trades within a single day.
                            <br />- Medium term - SWING:
                            <br />
                            Choose this option if you prefer holding your trades
                            for a few days to a few weeks.
                            <br />- Long term - INVESTMENT:
                            <br />
                            Select this option if you have a long-term
                            investment approach and plan to hold your trades for
                            an extended period
                          </>
                        }
                      </UncontrolledTooltip>
                    </Label>
                    <Col md="5">
                      <select
                        name="trading_type"
                        label="trading_type"
                        className="form-select"
                        onChange={(e) => handleTrendSettingsChange(e)}
                        value={trendSettings.trading_type}
                      >
                        <option value="short_term">
                          Short term - INTRADAY
                        </option>
                        <option value="medium_term">Medium term - SWING</option>
                        <option value="long_term">
                          Long term - INVESTMENT
                        </option>
                      </select>
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="formrow-firstname-Input"
                      md="4"
                      className="col-form-label"
                    >
                      Method for Trend signal:{" "}
                      <i
                        className="bx bx-info-circle"
                        id="Method_for_Trend_signal"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="Method_for_Trend_signal"
                      >
                        {ToolTipData.method_for_trend_signal}
                      </UncontrolledTooltip>
                    </Label>
                    <Col md="5">
                      <select
                        name="trend_method"
                        label="trend_method"
                        className="form-select"
                        onChange={(e) => handleTrendSettingsChange(e)}
                        value={trendSettings.trend_method}
                      >
                        <option value="sma">SMA crossovers</option>
                        <option value="ema">EMA crossovers</option>
                        <option value="macd">MACD Signal Line cross</option>
                        <option value="supertrend">Supertrend</option>
                      </select>
                    </Col>
                  </FormGroup>

                  {!trendSettings.trend_method ||
                    trendSettings.trend_method == "sma" ? (
                    <>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Interval{" "}
                          <i className="bx bx-info-circle" id="Interval1" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Interval1"
                          >
                            {/* {ToolTipData.sma_crossover.interval} */}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <select
                            name="time_frame"
                            label="time_frame"
                            required
                            className="form-select"
                            style={
                              DefaultColorSetting.time_frame
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.time_frame
                                ? trendSettings.time_frame
                                : "15m"
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                          >
                            <option value="1m">1 minute</option>
                            <option value="5m">5 minutes</option>
                            <option value="15m">15 minutes</option>
                            <option value="30m">30 minutes</option>
                            <option value="1h">1 hour</option>
                            <option value="2h">2 hours</option>
                            <option value="4h">4 hours</option>
                            <option value="1d">1 day</option>
                            <option value="1w">1 week</option>
                            <option value="1M">1 month</option>
                          </select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Short length{" "}
                          <i className="bx bx-info-circle" id="Short_length" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Short_length"
                          >
                            {/* {ToolTipData.sma_crossover.short_length} */}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.short_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.short_term
                                ? trendSettings.short_term
                                : 9
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="short_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Long length{" "}
                          <i className="bx bx-info-circle" id="Long_length" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Long_length"
                          >
                            {/* {ToolTipData.sma_crossover.long_length} */}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.long_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.long_term
                                ? trendSettings.long_term
                                : 20
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="long_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                    </>
                  ) : trendSettings.trend_method == "ema" ? (
                    <>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Interval{" "}
                          <i className="bx bx-info-circle" id="TFBMINTREVAL" />
                          <UncontrolledTooltip
                            placement="top"
                            target="TFBMINTREVAL"
                          >
                            {ToolTipData.ema_crossover.interval}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <select
                            name="ema_time_frame"
                            label="ema_time_frame"
                            required
                            className="form-select"
                            style={
                              DefaultColorSetting.ema_time_frame
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.ema_time_frame
                                ? trendSettings.ema_time_frame
                                : "15m"
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                          >
                            <option value="1m">1 minute</option>
                            <option value="5m">5 minutes</option>
                            <option value="15m">15 minutes</option>
                            <option value="30m">30 minutes</option>
                            <option value="1h">1 hour</option>
                            <option value="2h">2 hours</option>
                            <option value="4h">4 hours</option>
                            <option value="1d">1 day</option>
                            <option value="1w">1 week</option>
                            <option value="1M">1 month</option>
                          </select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Short length{" "}
                          <i className="bx bx-info-circle" id="ema_short" />
                          <UncontrolledTooltip
                            placement="top"
                            target="ema_short"
                          >
                            {ToolTipData.ema_crossover.short_length}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            onWheel={handleWheel}
                            inputMode="numeric"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.ema_short_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.ema_short_term
                                ? trendSettings.ema_short_term
                                : 9
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="ema_short_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Long length{" "}
                          <i className="bx bx-info-circle" id="ema_long" />
                          <UncontrolledTooltip
                            placement="top"
                            target="ema_long"
                          >
                            {ToolTipData.ema_crossover.long_length}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            onWheel={handleWheel}
                            inputMode="numeric"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.ema_long_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.ema_long_term
                                ? trendSettings.ema_long_term
                                : 21
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="ema_long_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                    </>
                  ) : trendSettings.trend_method == "macd" ? (
                    <>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Interval{" "}
                          <i className="bx bx-info-circle" id="Interval" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Interval"
                          >
                            {ToolTipData.macd_sign_line_cross.interval}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <select
                            name="macd_time_frame"
                            required
                            label="macd_time_frame"
                            className="form-select"
                            style={
                              DefaultColorSetting.macd_time_frame
                                ? {}
                                : { color: "gray" }
                            }
                            value={trendSettings.macd_time_frame}
                            onChange={(e) => handleTrendSettingsChange(e)}
                          >
                            <option value="1m">1 minute</option>
                            <option value="5m">5 minutes</option>
                            <option value="15m">15 minutes</option>
                            <option value="30m">30 minutes</option>
                            <option value="1h">1 hour</option>
                            <option value="2h">2 hours</option>
                            <option value="4h">4 hours</option>
                            <option value="1d">1 day</option>
                            <option value="1w">1 week</option>
                            <option value="1M">1 month</option>
                          </select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Short length{" "}
                          <i className="bx bx-info-circle" id="Short_length" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Short_length"
                          >
                            {ToolTipData.macd_sign_line_cross.short_length}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.macd_short_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.macd_short_term
                                ? trendSettings.macd_short_term
                                : 12
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="macd_short_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Long length{" "}
                          <i className="bx bx-info-circle" id="Long_length" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Long_length"
                          >
                            {ToolTipData.macd_sign_line_cross.long_length}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.macd_long_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.macd_long_term
                                ? trendSettings.macd_long_term
                                : 26
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="macd_long_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Signal length{" "}
                          <i className="bx bx-info-circle" id="Signal_length" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Signal_length"
                          >
                            {ToolTipData.macd_sign_line_cross.signal_length}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.macd_signal_term
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.macd_signal_term
                                ? trendSettings.macd_signal_term
                                : 9
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="macd_signal_term"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                    </>
                  ) : trendSettings.trend_method == "supertrend" ? (
                    <>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Interval{" "}
                          <i className="bx bx-info-circle" id="Interval" />
                          <UncontrolledTooltip
                            placement="top"
                            target="Interval"
                          >
                            {ToolTipData.supertrend.interval}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <select
                            name="st_time_frame"
                            required
                            label="st_time_frame"
                            className="form-select"
                            style={
                              DefaultColorSetting.st_time_frame
                                ? {}
                                : { color: "gray" }
                            }
                            value={trendSettings.st_time_frame}
                            onChange={(e) => handleTrendSettingsChange(e)}
                          >
                            <option value="1m">1 minute</option>
                            <option value="5m">5 minutes</option>
                            <option value="15m">15 minutes</option>
                            <option value="30m">30 minutes</option>
                            <option value="1h">1 hour</option>
                            <option value="2h">2 hours</option>
                            <option value="4h">4 hours</option>
                            <option value="1d">1 day</option>
                            <option value="1w">1 week</option>
                            {/*<option value='1M'>1 month</option>*/}
                          </select>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          ATR length{" "}
                          <i className="bx bx-info-circle" id="ATR_length" />
                          <UncontrolledTooltip
                            placement="top"
                            target="ATR_length"
                          >
                            {ToolTipData.supertrend.atr_length}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={1}
                            style={
                              DefaultColorSetting.st_atr_length
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.st_atr_length
                                ? trendSettings.st_atr_length
                                : 10
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="st_atr_length"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Factor <i className="bx bx-info-circle" id="Factor" />
                          <UncontrolledTooltip placement="top" target="Factor">
                            {ToolTipData.supertrend.factor}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            required
                            min={1}
                            step={0.1}
                            style={
                              DefaultColorSetting.st_factor
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.st_factor
                                ? trendSettings.st_factor
                                : 2
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="st_factor"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Minimum supertrend distance{" "}
                          <i
                            className="bx bx-info-circle"
                            id="Minimum_supertrend_distance"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="Minimum_supertrend_distance"
                          >
                            {ToolTipData.supertrend.minimum_supertrend_distance}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            step={0.00001}
                            style={
                              DefaultColorSetting.st_min_distance
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.st_min_distance
                                ? trendSettings.st_min_distance
                                : ""
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="st_min_distance"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="formrow-firstname-Input"
                          md="4"
                          className="col-form-label"
                        >
                          Maximum supertrend distance{" "}
                          <i
                            className="bx bx-info-circle"
                            id="Maximum_supertrend_distance"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="Maximum_supertrend_distance"
                          >
                            {ToolTipData.supertrend.maximum_supertrend_distance}
                          </UncontrolledTooltip>
                        </Label>
                        <Col md="8">
                          <input
                            type="number"
                            step={1}
                            style={
                              DefaultColorSetting.st_max_distance
                                ? {}
                                : { color: "gray" }
                            }
                            value={
                              trendSettings.st_max_distance
                                ? trendSettings.st_max_distance
                                : ""
                            }
                            onChange={(e) => handleTrendSettingsChange(e)}
                            onBlur={(e) => handleTrendSettingsChange(e)}
                            name="st_max_distance"
                            className="form-control"
                          />
                        </Col>
                      </FormGroup>
                      {/* </div> */}
                    </>
                  ) : (
                    ""
                  )}
                  <FormGroup className="mb-4" row>
                    <TradingView
                      data={`${Trendfollowpair.exchange_id ===
                        "binance_us_paper_trade" || "tradeogre"
                        ? "binance"
                        : Trendfollowpair.exchange_id
                        }:${Trendfollowpair.display_text},${trendSettings.trend_method
                        },${trendSettings.time_frame ? trendSettings.time_frame : ""
                        },${trendSettings.short_term ? trendSettings.short_term : 9
                        },${trendSettings.long_term ? trendSettings.long_term : 20
                        },${trendSettings.ema_time_frame
                          ? trendSettings.ema_time_frame
                          : ""
                        },${trendSettings.ema_short_term
                          ? trendSettings.ema_short_term
                          : 9
                        },${trendSettings.ema_long_term
                          ? trendSettings.ema_long_term
                          : 21
                        },${trendSettings.macd_time_frame
                          ? trendSettings.macd_time_frame
                          : ""
                        },${trendSettings.macd_short_term
                          ? trendSettings.macd_short_term
                          : 12
                        },${trendSettings.macd_long_term
                          ? trendSettings.macd_long_term
                          : 26
                        },${trendSettings.macd_signal_term
                          ? trendSettings.macd_signal_term
                          : 9
                        },${trendSettings.st_time_frame
                          ? trendSettings.st_time_frame
                          : ""
                        },${trendSettings.st_atr_length
                          ? trendSettings.st_atr_length
                          : 10
                        },${trendSettings.st_factor ? trendSettings.st_factor : 2
                        },${trendSettings.st_min_distance
                          ? trendSettings.st_min_distance
                          : ""
                        },${trendSettings.st_max_distance
                          ? trendSettings.st_max_distance
                          : ""
                        }`}
                    />
                  </FormGroup>
                </div>
                {ShowWarning ? (
                  <div>
                    {" "}
                    <div className="alert alert-danger" role="alert">
                      <strong>Warning!</strong> Please ensure all open orders
                      for {Trendfollowpair.display_text} are closed before
                      updating the Trend settings.
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="text-sm-end mt-3">
                  <Button
                    className="btn-label w-md"
                    color="success"
                    type="submit"
                    disabled={form6_loader.loading}
                  >
                    {form6_loader.loading ? (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                    ) : (
                      ""
                    )}
                    <i className="bx bx-smile label-icon"></i> Submit
                  </Button>
                </div>
              </form>
            </TabPane>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={isDeleteconfirmpopup}
        toggle={() => {
          setisDeleteconfirmpopup(false);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setisDeleteconfirmpopup(false);
          }}
          tag="h4"
          className="text-capitalize"
        >
          {"Delete " + PairId + " Pair"}
        </ModalHeader>
        <ModalBody>
          <p className="">Are you sure you want to delete {PairId} Pair?</p>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              handleDelete();
            }}
          >
            {ShowWarning ? (
              <div>
                {" "}
                <div
                  className="alert alert-danger"
                  style={{ fontSize: "10px" }}
                  role="alert"
                >
                  <strong>Warning!</strong> Please ensure all open orders for{" "}
                  {PairId ? PairId.replace("_", "/") : ""} are closed before
                  deleting.
                </div>
              </div>
            ) : (
              ""
            )}
            <Row>
              <Col className="col-12"></Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className={
                      loader
                        ? "btn btn-danger save-user disabled"
                        : "btn btn-danger save-user"
                    }
                  >
                    {loader ? (
                      <span>
                        <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                        Processing...{" "}
                      </span>
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={pasteSettingsModal}
        toggle={() => {
          setPasteSettingsModal(false);
        }}
        style={{ width: "100%" }}
        className="modal-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Are you sure want to apply the settings for{" "}
            <b className="text-primary">{pastePair.pair}</b>?
          </h5>
          <button
            type="button"
            onClick={() => {
              setPasteSettingsModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }} className="border-end">
              <h5>Order Settings</h5>
              {copiedSettings.ordersettings &&
                copiedSettings.ordersettings.stop_loss ? (
                <p className=" font-size-11">
                  {/* Quantity:{" "}
                  <b>
                    {copiedSettings.ordersettings.quantity ||
                      copiedSettings.ordersettings.qty}
                    {copiedSettings.ordersettings.quantity_type == "balance_per"
                      ? "% of available quote currency margin"
                      : ""}
                    {copiedSettings.ordersettings.quantity_type ==
                    "balance_per_base"
                      ? "% of available base currency margin"
                      : ""}
                  </b> */}
                  {copiedSettings.ordersettings.fill_type &&
                    copiedSettings.ordersettings.fill_type.length !== 0 && (
                      <>
                        {" "}
                        <br /> Fill type:{" "}
                        <b>
                          {copiedSettings.ordersettings.fill_type ===
                            "instant_fill"
                            ? "Instant Fill"
                            : "Wait and Fill"}
                        </b>
                      </>
                    )}
                  {copiedSettings.ordersettings.price_distance_percentage &&
                    copiedSettings.ordersettings.fill_type !==
                    "instant_fill" && (
                      <>
                        {" "}
                        <br /> Price Distance % for Wait and Fill:{" "}
                        <b>
                          {
                            copiedSettings.ordersettings
                              .price_distance_percentage
                          }
                        </b>
                      </>
                    )}
                  {copiedSettings.ordersettings.stop_loss &&
                    copiedSettings.ordersettings.stop_loss.length !== 0 && (
                      <>
                        {" "}
                        <br /> Market Stop Loss:{" "}
                        <b>
                          {copiedSettings.ordersettings.stop_loss}
                          {copiedSettings.ordersettings.stop_loss_type ===
                            "points"
                            ? "Pts"
                            : "%"}
                        </b>
                      </>
                    )}
                  {copiedSettings.ordersettings.min_take_profit_percentage && (
                    <>
                      {" "}
                      <br /> Minimum Take Profit%:{" "}
                      <b>
                        {
                          copiedSettings.ordersettings
                            .min_take_profit_percentage
                        }
                      </b>
                    </>
                  )}
                  {copiedSettings.ordersettings
                    .trailing_take_profit_percentage && (
                      <>
                        {" "}
                        <br /> Trailing Take Profit%:{" "}
                        <b>
                          {
                            copiedSettings.ordersettings
                              .trailing_take_profit_percentage
                          }
                        </b>
                      </>
                    )}
                  {copiedSettings.ordersettings
                    .waiting_time_for_next_order_stop_loss && (
                      <>
                        {" "}
                        <br /> Waiting Time for Order after Stop Loss:{" "}
                        <b>
                          {timeFormat(
                            copiedSettings.ordersettings
                              .waiting_time_for_next_order_stop_loss
                          )}
                        </b>
                      </>
                    )}
                  {copiedSettings.ordersettings
                    .waiting_time_for_next_order_trailing_profit && (
                      <>
                        {" "}
                        <br /> Waiting Time for Order after Trailing Take Profit:{" "}
                        <b>
                          {timeFormat(
                            copiedSettings.ordersettings
                              .waiting_time_for_next_order_trailing_profit
                          )}
                        </b>
                      </>
                    )}
                  {copiedSettings.ordersettings.order_side && (
                    <>
                      {" "}
                      <br /> Order Side:{" "}
                      <b>{copiedSettings.ordersettings.order_side}</b>
                    </>
                  )}
                  {copiedSettings.ordersettings.order_side === "sell" &&
                    copiedSettings.ordersettings.min_sell_price && (
                      <>
                        {" "}
                        <br /> Minimum Sell Price:{" "}
                        <b>{copiedSettings.ordersettings.min_sell_price}</b>
                        <br />
                      </>
                    )}
                  {copiedSettings.ordersettings.order_side === "buy" &&
                    copiedSettings.ordersettings.max_buy_price && (
                      <>
                        {" "}
                        <br /> Maximum Buy Price:{" "}
                        <b>{copiedSettings.ordersettings.max_buy_price}</b>
                        <br />
                      </>
                    )}
                </p>
              ) : (
                <div className="text-warning font-size-11">
                  No Order Settings available
                </div>
              )}
            </div>

            <div style={{ width: "48%" }}>
              <h5>Trend Settings</h5>
              {copiedSettings.trendsettings ? (
                <div className="font-size-11">{renderTrendSettings()}</div>
              ) : (
                <div className="text-warning font-size-11">
                  No Trend Settings available
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              pasteApply();
            }}
            disabled = {settingsLoad}
            className="btn btn-primary"
            // data-dismiss="modal"
          >
           {settingsLoad ? (
            <span>
              <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
              Processing...{" "}
            </span>
          ) : (
            "Apply Settings"
          )}
           
          </button>
          <button
            type="button"
            onClick={() => {
              setPasteSettingsModal(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TradingPairs;
